import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import crypto from "crypto-js";
import { useNavigate } from "react-router-dom";
import { ENDPOINTS, SECRET_KEY, getUserInfo, httpService } from "../../api";
import { RoleType } from "@models";
import jwtDecode from "jwt-decode";
import { UserInfoViewModel } from "@viewModels";

type LoginType = {
  username: string;
  password: string;
};

const LoginForm = () => {
  const navigate = useNavigate();
  const validationSchema = Yup.object()
    .shape({
      username: Yup.string().required("Username is required"),
      password: Yup.string().required("Password is required"),
    })
    .required();

  const formOptions = { resolver: yupResolver(validationSchema) };

  const {
    formState: { isSubmitting },
    handleSubmit,
    register,
  } = useForm<LoginType>();

  // const onSubmit = async (values: any) => {
  //   const res = await httpService(ENDPOINTS.Auth).post(values);

  //   localStorage.setItem("token", res.data.token);

  //   const user = getCurrentUser();

  //   user?.roleName === RoleType.ADMIN
  //     ? navigate("/dashboard")
  //     : navigate("/dashboard/attendance");

  //   window.location.reload();
  // };

  const onSubmit = async (values: LoginType) => {
    const res = await httpService(ENDPOINTS.Auth).post(values);

    if (res && res.status === 200) {
      const userInfo = decodeToken(res?.data?.token);

      // Encrypt
      var ciphertext = crypto.AES.encrypt(
        JSON.stringify(userInfo),
        SECRET_KEY
      ).toString();

      localStorage.clear();

      localStorage.setItem("sys_user", JSON.stringify(ciphertext));
      const user = getUserInfo();

      user?.roleName === RoleType.ADMIN
        ? navigate("/dashboard")
        : navigate("/dashboard/attendance");

      window.location.reload();
    }
  };

  const decodeToken = (token: string) => {
    var decode: any = jwtDecode(token);

    var userInfo: UserInfoViewModel = {
      userId: decode?.userId,
      userName: decode?.userName,
      fullName: decode?.fullName,
      roleName: decode?.roleName,
      rcdCode: decode?.rcdCode,
      classId: decode?.classId,
      email: decode?.email,
      // roleId: decode?.roleId,
      // roleName: decode?.roleName,
      // accessLevel: decode?.accessLevel,
      // branchId: decode?.branchId,

      token: token,
    };

    return userInfo;
  };

  return (
    <form className="text-left" onSubmit={handleSubmit(onSubmit)}>
      <div className="form">
        <div id="username-field" className="field-wrapper input">
          <label htmlFor="username">USERNAME</label>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-user"
          >
            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
            <circle cx={12} cy={7} r={4} />
          </svg>
          <input
            id="username"
            type="text"
            className="form-control"
            placeholder="Username"
            {...register("username")}
          />
        </div>
        <div id="password-field" className="field-wrapper input mb-2">
          <div className="d-flex justify-content-between">
            <label htmlFor="password">PASSWORD</label>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-lock"
          >
            <rect x={3} y={11} width={18} height={11} rx={2} ry={2} />
            <path d="M7 11V7a5 5 0 0 1 10 0v4" />
          </svg>
          <input
            id="password"
            type="password"
            className="form-control"
            placeholder="Password"
            {...register("password")}
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            id="toggle-password"
            className="feather feather-eye"
          >
            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
            <circle cx={12} cy={12} r={3} />
          </svg>
        </div>
        <div className="w-100">
          <div className="field-wrapper w-100 ">
            <button
              type="submit"
              style={{ backgroundColor: "#158f0a" }}
              className="btn text-white mt-3 d-flex align-items-center justify-content-center"
            >
              {isSubmitting && (
                <span
                  style={{ fontSize: "10px", textAlign: "center" }}
                  className="spinner-border text-white mr-4"
                />
              )}
              <span>Log In</span>
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default LoginForm;
