import { useState } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import DatePicker from "react-datepicker";
import { useFieldArray, useForm } from "react-hook-form";
import { BsPlusSquareFill, BsXSquareFill } from "react-icons/bs";
import { ENDPOINTS, httpService } from "@api";
import ClassFamilyStudentFilter from "../dropdowns/classFamilyStudentFitler";

type Props = {
  reload: boolean;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddStudentMilestone = ({ reload, setReload }: Props) => {
  const [studentId, setStudentId] = useState<number>();
  const [familyId, setFamilyId] = useState<number>();
  const [classId, setClassId] = useState<number>();

  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());

  const validationSchema = Yup.object()
    .shape({
      milestones: Yup.array(
        Yup.object().shape({
          subject: Yup.string().required("Name is required"),
          semester: Yup.string().required("Semester is required"),
          target: Yup.string().required("Target is required"),
          current: Yup.string().required("Current is required"),
        })
      ),
    })
    .required();

  const formOptions = {
    resolver: yupResolver(validationSchema),
  };

  const {
    handleSubmit,
    register,
    control,
    formState: { isSubmitting },
  } = useForm(formOptions);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "milestones", // unique name for the Field Array
  });

  const handleOnSubmit = async (values: any) => {
    const year = selectedDate?.getFullYear();
    const data = { year, studentId, ...values };

    await httpService(ENDPOINTS.StudentsMilestone).post(data);

    setReload(!reload);
  };

  // useEffect(() => {
  //   const options = new PagingOptions();
  //   options.size = 1000;

  //   (async () => {
  //     const res = await httpService(ENDPOINTS.Classes, options).getAll();
  //     setClasses(res.data.items ?? []);
  //   })();

  //   (async () => {
  //     const res = await httpService(ENDPOINTS.Families, options).getAll();
  //     setFamilies(res.data.items ?? []);
  //   })();
  // }, []);

  const onStudentChange = (id: number) => {
    setStudentId(id);
  };

  const onFamilyChange = (id: number) => {
    setFamilyId(id);
  };

  const onClassChange = (id: number) => {
    setClassId(id);
  };

  return (
    <form
      onSubmit={handleSubmit(handleOnSubmit)}
      className="d-flex flex-column w-100 mt-4"
    >
      <div className="d-flex">
        <span className="mr-3">
          <DatePicker
            className="form-control form-control-sm"
            onChange={(e) => {
              setSelectedDate(e);
            }}
            selected={selectedDate}
            showYearPicker
            dateFormat="yyyy"
            placeholderText="Select a year"
          />
        </span>
        <ClassFamilyStudentFilter
          onStudentChange={onStudentChange}
          onFamilyChange={onFamilyChange}
          onClassChange={onClassChange}
        />
      </div>
      <div className="row w-100">
        {fields.map((item: any, idx) => {
          return (
            <>
              <div className="col-sm-3 col-md-3 col-lg-3 py-3">
                <select
                  {...register(`milestones.${idx}.subject`, {
                    required: "name is required",
                  })}
                  id="name"
                  className="form-control"
                >
                  <option value="">Select Subject</option>
                  {["Quran (hifdi)", "Quran (Qira'ah)", "Higaad"].map((g) => (
                    <option key={g} value={g}>
                      {g}
                    </option>
                  ))}
                </select>{" "}
              </div>
              <div className="col-sm-3 col-md-3 col-lg-3 py-3">
                <select
                  {...register(`milestones.${idx}.semester`, {
                    required: "name is required",
                  })}
                  id="name"
                  className="form-control"
                >
                  <option value="">Select Semester</option>
                  {[
                    "Semester One (JAN - JUNE)",
                    "Semester Two (JULY - DEC)",
                  ].map((g) => (
                    <option key={g} value={g} disabled={g === item.name}>
                      {g}
                    </option>
                  ))}
                </select>{" "}
              </div>
              <div className="col-sm-3 col-md-3 col-lg-3 py-3">
                <input
                  type="text"
                  className="form-control mr-2"
                  id="name"
                  placeholder="Current"
                  {...register(`milestones.${idx}.current`, {
                    required: "current is required",
                  })}
                />
              </div>
              <div className="col-sm-3 col-md-3 col-lg-3 py-3 d-flex">
                <input
                  type="text"
                  className="form-control mr-2"
                  id="name"
                  placeholder="Target"
                  {...register(`milestones.${idx}.target`, {
                    required: "target is required",
                  })}
                />{" "}
                <div className="col-sm-3 col-md-3 col-lg-3 d-flex align-items-center justify-content-center">
                  <BsXSquareFill
                    style={{
                      cursor: "pointer",
                      color: "#e7515a",
                    }}
                    fontSize="30px"
                    onClick={() => remove(idx)}
                  />
                </div>
              </div>
            </>
          );
        })}
      </div>

      <div style={{ alignSelf: "flex-start" }} className="mt-2">
        <BsPlusSquareFill
          style={{ cursor: "pointer" }}
          fontSize="30px"
          onClick={() => {
            append({
              subject: "",
              semester: "",
              target: "",
              current: "",
            });
          }}
        />
      </div>
      <div className="d-flex justify-content-end">
        <button
          type="submit"
          className="btn btn-success mt-4 d-flex align-items-center justify-content-center"
          disabled={
            studentId === undefined ||
            familyId === undefined ||
            selectedDate === undefined
          }
        >
          {isSubmitting && (
            <span
              style={{ fontSize: "10px", textAlign: "center" }}
              className="spinner-border text-white mr-4"
            />
          )}
          Save
        </button>
      </div>
    </form>
  );
};

export default AddStudentMilestone;
