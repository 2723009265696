import { Helmet } from "react-helmet";
import LoginForm from "../../components/forms/login";

const Login = () => {
  return (
    <>
      <RenderHelmet />
      <div className="form-container outer">
        <div className="form-form">
          <div className="form-form-wrap">
            <div className="form-container">
              <div className="form-content">
                <img
                  alt="logo"
                  src="/assets/img/amiinlogo.png"
                  style={{
                    width: "200px",
                  }}
                  className="mb-2"
                />
                <h1 className="my-2">Log In</h1>
                <LoginForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function RenderHelmet() {
  return (
    <Helmet>
      <link
        href="/assets/css/authentication/form-2.css"
        rel="stylesheet"
        type="text/css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="/assets/css/forms/theme-checkbox-radio.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="/assets/css/forms/switches.css"
      />
      <script src="assets/js/authentication/form-2.js" />
    </Helmet>
  );
}
export default Login;
