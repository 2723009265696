import { forwardRef } from "react";
import { toUpperCaseFirstLetter } from "../../helpers/fnHelpers";
import { ClassDay, Milestones, StudentMilestone } from "@models";
import { FaEdit } from "react-icons/fa";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { EditClassPeriod, EditStudentMilestone } from "@components";

export const StudentMilestoneTable = forwardRef((props: any, ref: any) => {
  const toggle = withReactContent(Swal);

  const handleUpdate = (data: { id: number; milestones: Milestones[] }) => {
    toggle.fire({
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
      width: "70%",
      html: (
        <EditStudentMilestone
          data={data}
          setReload={props.setReload}
          reload={props.reload}
        />
      ),
    });
  };

  const isLastItem = (arr: Milestones[], item: Milestones) => {
    return arr[arr.length - 1] !== item;
  };

  return (
    <div ref={ref} className="d-flex bg-white flex-column mt-4">
      <div className="d-flex justify-content-center my-5">
        <div className="mr-4">
          <h6 className="font-weight-bold text-align-center">
            Amiin Quran Online
          </h6>
          <hr />
        </div>
        <div className="align-self-center">
          <img src="/assets/img/logo_green.png" alt="logo" />
        </div>
        <div className="ml-3">
          <h6 className="font-weight-bold text-align-center">Milestone</h6>
          <hr />
        </div>
      </div>

      <table className="table bg-white px-5 mt-4">
        <thead className="bg-white">
          <tr>
            <th className="text-left " scope="col">
              Family
            </th>
            <th className="text-left " scope="col">
              Student
            </th>
            <th className="text-left " scope="col">
              Subject
            </th>
            <th className="text-left " scope="col">
              Semester
            </th>
            <th className="text-left " scope="col">
              Current
            </th>
            <th className="text-left " scope="col">
              Target
            </th>
            <th className="text-left " scope="col">
              Achieved
            </th>
          </tr>
        </thead>
        <tbody className="px-5">
          {props?.milestones.map((sm: StudentMilestone) => {
            return (
              <>
                {sm.milestones.map((ms: Milestones, idx) => {
                  console.log("idx", idx);

                  let hidden =
                    sm.milestones.length > 1 && isLastItem(sm.milestones, ms)
                      ? "hidden"
                      : "";

                  return (
                    <tr key={ms.subject} style={{ borderBottom: hidden }}>
                      <td
                        className="text-left font-weight-bold"
                        style={{ borderBottom: hidden }}
                      >
                        {toUpperCaseFirstLetter(
                          sm?.student?.family?.name ?? ""
                        )}
                      </td>
                      <td
                        className="text-left font-weight-bold"
                        style={{ borderBottom: hidden }}
                      >
                        {toUpperCaseFirstLetter(
                          `${sm.student.firstName} ${sm.student.secondName}`
                        )}
                      </td>
                      <td
                        className="text-left font-weight-bold"
                        style={{ borderBottom: hidden }}
                      >
                        {toUpperCaseFirstLetter(ms.subject)}
                      </td>
                      <td
                        className="text-left font-weight-bold"
                        style={{ borderBottom: hidden }}
                      >
                        {toUpperCaseFirstLetter(ms.semester)}
                      </td>
                      <td
                        className="text-left font-weight-bold"
                        style={{ borderBottom: hidden }}
                      >
                        {toUpperCaseFirstLetter(ms.current)}
                      </td>
                      <td
                        className="text-left font-weight-bold"
                        style={{ borderBottom: hidden }}
                      >
                        {toUpperCaseFirstLetter(ms.target)}
                      </td>
                      <td
                        className="text-left font-weight-bold"
                        style={{ borderBottom: hidden }}
                      >
                        {toUpperCaseFirstLetter(ms.achieved ?? "")}
                      </td>
                      {idx === 0 && (
                        <td
                          className="text-right"
                          style={{ borderBottom: hidden }}
                        >
                          <FaEdit
                            size={20}
                            color="#e7515a"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              handleUpdate({
                                id: sm.id,
                                milestones: sm.milestones,
                              })
                            }
                          />
                        </td>
                      )}
                    </tr>
                  );
                })}
              </>
            );
          })}
        </tbody>
      </table>
    </div>
  );
});
