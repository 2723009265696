import { forwardRef } from "react";
import { toUpperCaseFirstLetter } from "../../helpers/fnHelpers";
import { ClassDay, ClassPeriod } from "@models";
import { FaEdit } from "react-icons/fa";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { EditClassPeriod } from "@components";
import moment from "moment";

export const ClassPeriodsTable = forwardRef((props: any, ref: any) => {
  const dayOrder = [
    "Saturday",
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
  ];

  const toggle = withReactContent(Swal);

  const completeDays = (days: ClassDay[]) => {
    const completeDays = dayOrder.map((day) => {
      const entry = days.find((d) => d.name === day);
      return entry || { name: day, time: "" };
    });

    return completeDays;
  };

  const handleUpdate = (data: { id: number; days: ClassDay[] }) => {
    toggle.fire({
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
      width: "40%",
      html: (
        <EditClassPeriod
          data={data}
          setReload={props.setReload}
          reload={props.reload}
        />
      ),
    });
  };

  return (
    <div ref={ref} className="d-flex bg-white flex-column mt-4">
      <div className="d-flex justify-content-center my-5">
        <div className="mr-4">
          <h6 className="font-weight-bold text-align-center">
            Amiin Quran Online
          </h6>
          <hr />
        </div>
        <div className="align-self-center">
          <img src="/assets/img/logo_green.png" alt="logo" />
        </div>
        <div className="ml-3">
          <h6 className="font-weight-bold text-align-center">Periods</h6>
          <hr />
        </div>
      </div>

      <table className="table bg-white px-5 mt-4">
        <thead className="bg-white">
          <tr>
            <th className="text-left " scope="col">
              Family
            </th>
            {dayOrder.map((day) => {
              return (
                <th className="text-left " scope="col">
                  {day}
                </th>
              );
            })}
            <th>#</th>
          </tr>
        </thead>
        <tbody className="px-5">
          {props?.classPeriods.map((cp: ClassPeriod) => {
            return (
              <tr key={cp.familyId}>
                <td className="text-left font-weight-bold">
                  {toUpperCaseFirstLetter(cp.family.name)}
                </td>

                {props.selectedTime
                  ? completeDays(
                      cp.days?.filter(
                        (day) =>
                          moment(day.time).format("HH") === props.selectedTime
                      )
                    ).map((day) => {
                      return (
                        <td className="text-left font-weight-bold">
                          {day.time == ""
                            ? "-"
                            : moment(day.time).format("HH:mm")}{" "}
                        </td>
                      );
                    })
                  : completeDays(cp.days).map((day) => {
                      return (
                        <td className="text-left font-weight-bold">
                          {day.time == ""
                            ? "-"
                            : moment(day.time).format("HH:mm")}{" "}
                        </td>
                      );
                    })}
                <td className="">
                  <FaEdit
                    size={20}
                    color="#e7515a"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleUpdate({ id: cp.id, days: cp.days })}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* <div className="d-flex flex-column mx-4">
        <div className="d-flex justify-content-between">
          <div className="">
            <h6 className="font-weight-bold text-align-center">
              Performance:{" "}
              {calculatePercentagePerformance(props.classMonthlyAttendance)}
            </h6>
          </div>
        </div>
      </div> */}
    </div>
  );
});
